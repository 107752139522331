import controller from "./connectionSettings.controller";

/* connectionSettings.component.js */

/**
 * @desc component for configuring product connection settings.
 * @example <connection-settings></connection-settings>
 */

const connectionSettings = {
    bindings: {
        product: '<',
        currentConfig: '=',
        originalConfig: '<',
        configUpdate: '<',
        updateConfig: '<',
        hybridConnectionsArray: '<',
        configNames: '<',
        onUpdateNsRoleDropdown: '&'
    },
    controller: controller,
    controllerAs: 'csvm',
    templateUrl: '/App/Components/Connections/Connection/Settings/connectionSettings.tmpl.html'
};

export default connectionSettings;