import createSpinner from "../../../../Shared/Functions/createSpinner";
import { customAutoCompleteFilter } from "../../../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($rootScope, helperService, connectionsDataService) {

    const vm = this;

    vm.$onInit = init;

    vm.objectsDropdownChange = objectsDropdownChange;
    vm.updateField = updateField;
    vm.update = update;
    vm.getConfigNames = getConfigNames;
    vm.configValuesHaveChanged = configValuesHaveChanged;
    vm.productValuesHaveChanged = productValuesHaveChanged;
    vm.ax7ImportDisabledUsersChange = ax7ImportDisabledUsersChange;
    vm.ax7ImportDisabledUserAccessChange = ax7ImportDisabledUserAccessChange;
    vm.sfImportTypeChange = sfImportTypeChange;

   async function init() {
       let config = vm.product.Config;

        vm.versionOptions = vm.product.VersionOptions;
        vm.updateConfig = updateConfig;
        vm.originalProduct = Object.assign({}, vm.product);

        if (config && config.length) {
            vm.configNames = vm.product.Config.map((configItem) => configItem.Name);
        } else {
            vm.configNames = [];
        }

       if (vm.product.ID === "SF") {
           await setSalesForceObjects();
       }
    }

    function getConfigNames() {
        return vm.configNames;
    }

    function configValuesHaveChanged (configArray = []) {
        return helperService.returnTrueIfValuesDiffer(configArray, vm.originalConfig, vm.currentConfig);
    };

    function productValuesHaveChanged(product) {
        return !angular.equals(vm.originalProduct, product);
    }

    async function update() {
        vm.updatePending = new createSpinner();

        if (vm.product.ID === 'AX5') {
            updateSqlConnection(vm.product);
            vm.updateConfig(vm.product.Config, vm.currentConfig, vm.originalConfig);
        } else if (vm.product.ID === 'SL') {
            updateSqlConnection(vm.product);
        } else {
            vm.updateConfig(vm.product.Config, vm.currentConfig, vm.originalConfig);
        }
    }

    async function updateConfig(list, value, origVal) {
        let updateNsRoles = false,
            data = {
            "Configurations": []
        };

        list.forEach(item => {
            if (item.Name === 'NS_InactiveRoles') {
                updateNsRoles = true;
            }
            if (item.Encrypted === true) {
                if (value[item.Name] !== "password" && value[item.Name] !== "apikey") {
                    data.Configurations.push({
                        "Name": item.Name,
                        "Value": value[item.Name]
                    });
                }
            } else {
                if (value[item.Name] != origVal[item.Name]) {
                    data.Configurations.push({
                        "Name": item.Name,
                        "Value": value[item.Name]
                    });
                }
            }
        });

        vm.updatePending = new createSpinner();

        if (vm.product.ID === "NS") {
            try {
                await connectionsDataService.validateNsConfig(getNsValidationData(vm.currentConfig));
                await connectionsDataService.updateConfig(data);

                data.Configurations.forEach(config => {
                    origVal[config.Name] = config.Value;
                });
                helperService.successfulSaveButton(vm.updatePending);
                $rootScope.reloadConfigurationValues();

                if (updateNsRoles) {
                    const configChanged = true;
                    vm.onUpdateNsRoleDropdown({ configChanged });
                }
            } catch {
                vm.updatePending.loadingValue = false
            }
        } else {
            try {
                await connectionsDataService.updateConfig(data);

                data.Configurations.forEach(config => {
                    origVal[config.Name] = config.Value;
                });
                helperService.successfulSaveButton(vm.updatePending);
                $rootScope.reloadConfigurationValues();
            } catch {
                vm.updatePending.loadingValue = false
            }
        }
    };

    async function updateSqlConnection(product) {
        var postData = {
            "ProductId": product.ID,
            "DatabaseName": product.SQL_DatabaseName,
            "HostName": product.SQL_HostName,
            "Instance": product.SQL_Instance,
            "Port": product.SQL_Port,
            "Password": product.SQL_Password,
            "UserName": product.SQL_User
        };

        if (vm.originalConfig.SQL_Password === product.SQL_Password) {
            delete postData.Password;
        }

        vm.updatePending = new createSpinner();

        //Update Connection
        if (product.ConnectionExists === true) {
            try {
                await connectionsDataService.updateSqlConfig(product.ID, postData);
                helperService.successfulSaveButton(vm.updatePending);
            } catch {
                vm.updatePending.loadingValue = false;
            }
        }
        else {
            try {
                await connectionsDataService.createSqlConfig(product.ID, postData);
                product.ConnectionExists = true;
                helperService.successfulSaveButton(vm.updatePending);
            } catch {
                vm.updatePending.loadingValue = false;
            }
        }
    };

    async function setSalesForceObjects() {
        var fieldPermissionConfigurationGridColumns = [
            { template: "<label class='switch'><input type='checkbox' ng-model='dataItem.enabled' ng-change='csvm.updateField(dataItem)'><i></i></label>", width: 55, filterable: false },
            { field: "displayLabel", title: "Name", filterable: customAutoCompleteFilter }
        ];

        await getObjects();

        vm.fieldPermissionConfigurationGridOptions = helperService.setNonPageableGridOptions(fieldPermissionConfigurationGridColumns, 300);
        vm.fieldPermissionConfigurationGridOptions.autoBind = false;
        vm.fieldPermissionConfigurationGridOptions.noRecords = {
            template: "<div class='no-data-to-display-message'>No items to display</div>"
        };

        vm.fieldPermissionConfigurationGridDataSource = new kendo.data.DataSource({
            transport: {
                read: async options => {
                    try {
                        let response = await connectionsDataService.getSfPermissionConfigByObject(vm.selectedObject.name);
                        options.success(response.data);
                    } catch {
                        options.error();
                    }
                }
            }
        });
    }

    function ax7ImportDisabledUsersChange() {
        if (vm.currentConfig.AX7_AS_ShowDisabledUsers === "0") {
            vm.currentConfig.AX7_AS_ImportDisabledUserAccess = "0";
        }
    }

    function ax7ImportDisabledUserAccessChange() {
        if (vm.currentConfig.AX7_AS_ImportDisabledUserAccess === "1") {
            vm.currentConfig.AX7_AS_ShowDisabledUsers = "1";
        }
    }

    async function getObjects() {
        let response;

        vm.objectsDropdownLoading = true;

        try {
            response = await connectionsDataService.getSfPermissionConfig();

            if (typeof response.data === "string") {
                vm.invalidOrMissingCredentials = response.data;
            } else {
                vm.invalidOrMissingCredentials = null;
                vm.objectsDropdownDataSource = response.data;
                vm.selectedObject = vm.objectsDropdownDataSource[0];
                vm.objectsDropdownLoading = false;
                vm.fieldPermissionConfigurationGridDataSource.page(1);
                vm.objectsDropdownLoading = false;
            }
        } catch {
            vm.objectsDropdownLoading = false;
        }
    }

    function objectsDropdownChange() {
        vm.fieldPermissionConfigurationGridDataSource.read();
    }

    async function updateField(field) {
        if (field.enabled) {
            let data = {
                "Field": field.name,
                "FieldLabel": field.label,
                "SobjectType": vm.selectedObject.name,
                "ObjectLabel": vm.selectedObject.label
            },
                response;

            response = await connectionsDataService.createSfFieldPermissionConfig(data);
            field.Id = response.data;
        } else {
            await connectionsDataService.removeSfFieldPermissionConfig(field.Id);
        }
    }

    function getNsValidationData(config) {
        return {
            Account: config.NS_Account,
            TokenKey: config.NS_TokenKey,
            TokenSecret: config.NS_TokenSecret
        }
    }

    function sfImportTypeChange() {
        if (vm.currentConfig.SF_ImportType === '1') {
            vm.currentConfig.SF_UserName = null;
            vm.currentConfig.SF_Password = null;
            vm.currentConfig.SF_SecurityToken = null;
        } else {
            vm.currentConfig.SF_Url = null;
            vm.currentConfig.SF_ClientId = null;
            vm.currentConfig.SF_ClientSecret = null;
        }
    }
}
