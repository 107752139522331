import submitReviewModalController from "./SubmitReviewModal/submitReviewModal.controller";
import reviewDelegationModalController from "./DelegationModal/reviewDelegationModal.controller";
import ticketModalController from "./TicketModal/ticketModal.controller";
import notesModalController from "./notesModal/notesModal.controller";

export default /*@ngInject*/ function ($filter, $scope, $rootScope, $timeout, $window, configurationDataService, $location, $uibModal, accessCertificationsService, authenticationService, emailNavigationService, helperService, errorsDataService, myReviewsDataService) {

    const vm = this;
    const tenantInlocalStorage = angular.fromJson(localStorage.getItem("selectedTenant"));

    vm.pageParams = $location.search();
    vm.groupBy = '';
    vm.reviewDetailsSort = [];
    vm.selectedReviewGroups = [];
    vm.selectedGroupingMethod = null;
    vm.selectedReview = null;
    vm.selectedUser = null;
    vm.selectedGroup = null;
    vm.openNotesModal = openNotesModal;
    vm.helperService = helperService;

    vm.menuOptions = [
        {
            text: 'Export',
            click: item => {
                exportReview(item.review);
            }
        },
        {
            text: 'Delegate',
            click: item => {
                openDelegateModal(item.review);
            },
            displayed: item => {
                return (item.review.TotalItems - item.review.CompletedItems) !== 0;
            }
        },
        {
            text: 'View Tickets',
            click: item => {
                openTicketModal(item.review, 'View');
            },
            displayed: item => {
                return item.review.Tickets.length > 0;
            }
        }
    ];

    vm.groupMenuOptions = [
        {
            text: 'Delegate',
            click: item => {
                openDelegateModal(item.object);
            }
        }
    ];

    vm.selectUser = selectUser;
    vm.selectedGroupChanged = selectedGroupChanged;
    vm.setupGroupByList = setupGroupByList;
    vm.getReviews = getReviews;
    vm.selectReview = selectReview;
    vm.toggleReviews = toggleReviews;
    vm.resetReviews = resetReviews;
    vm.openSubmitReviewModal = openSubmitReviewModal;
    vm.changeItemStatus = changeItemStatus;
    vm.updateAllItemStatuses = updateAllItemStatuses;
    vm.updateItemNote = updateItemNote;
    vm.changeTypeItemStatus = changeTypeItemStatus;
    vm.openDelegateModal = openDelegateModal;
    vm.updateReviewCounts = updateReviewCounts
    vm.changeSearchFilter = changeSearchFilter;
    vm.radioFilterChanged = radioFilterChanged;

    activate();

    $scope.$on('DelegateReviewItems', (event, args) => activate());

    async function activate() {
        vm.currentUser = await authenticationService.getUser();
        vm.selfReviewConfigValue = await helperService.getConfigurationValue('Cert_SelfReview');
        vm.showAllCompaniesToggle = Number(await helperService.getConfigurationValue('Cert_AllowFilterByAllCompanies'));
        vm.username = vm.currentUser.fullName;
        vm.userDisplay = await getUserDisplay();
        await redirectIfNeeded();
        await getReviewTypes();
        await getReviews();
        setupMyReviewsListview();
        vm.groupBy = 'type';
        if (vm.pageParams.InstanceId) {
            selectReview(vm.reviews.find(review => review.ReviewInstanceId === vm.pageParams.InstanceId));
        }
    }

    function redirectIfNeeded() {
        if (vm.pageParams.tenantid) {
            if (vm.pageParams.tenantid === tenantInlocalStorage.ID) {
                vm.sufficientSecurityAccess = true;
                return;
            }
            else {
                findTenantFromEmail();
            }
        }
        else {
            vm.sufficientSecurityAccess = true;
            return;
        }
    }

    async function findTenantFromEmail() {
        emailNavigationService.availableTenants(vm.currentUser.userId)
            .then(response => {

                let validTenant = response.data.find(tenant => {
                    return tenant.ID === vm.pageParams.tenantid;
                });

                if (validTenant) {
                    vm.redirectToTenant = true;
                    localStorage.setItem("selectedTenant", JSON.stringify(angular.copy(validTenant)));
                    $window.location.reload();

                } else {
                    vm.insufficientSecurityAccess = true;
                }

            });
    }

    function getReviewTypes() {
        vm.reviewAssignmentTiles = accessCertificationsService.getReviewTypes();
    }

    async function getReviews() {
        try {
            const reviews = await myReviewsDataService.getReviews();
            reviews.data.forEach(review => {
                const match = vm.reviewAssignmentTiles.find(tile => tile.Id === review.ReviewType);
                review.Tile = match;
            });
            vm.reviews = reviews.data;

        } catch (err) {
            return;
        }
    }

    async function getUserDisplay() {
        try {
            return await helperService.getConfigurationValue('Cert_UserDisplayField');
        } catch (err) {
            errorsDataService.catch(err);
        }

    }

    function selectReview(review) {

        if (review === vm.selectedReview || review.Status === 'Processing') return;

        vm.selectedGroup = null;
        vm.selectedReviewGroups = [];
        vm.selectedUser = null;

        if (review.ReviewType === vm.selectedReview) {
            vm.selectedReview = null;
        } else {
            switch (review.ReviewType) {
                case "Risk":
                    vm.reviewTypeName = "Risks";
                    vm.reviewDetailsFilter = "RiskName";
                    vm.reviewDetailsSort = ["RiskName", "UserName"];
                    break;
                case "BusinessProcess":
                    vm.reviewTypeName = "Business Processes";
                    vm.reviewDetailsFilter = "BusinessProcessName";
                    vm.reviewDetailsSort = ["BusinessProcessName", "UserName"];
                    break;
                case "Product":
                    vm.reviewTypeName = "Products";
                    vm.reviewDetailsFilter = "ProductName";
                    vm.reviewDetailsSort = ["ProductName", "UserName"];
                    break;
                case "SecurityRole":
                case "SecurityRoleContent":
                    vm.reviewTypeName = "Roles";
                    vm.reviewDetailsFilter = "SecurityRoleName";
                    vm.reviewDetailsSort = ["SecurityRoleName", "UserName"];
                    break;
            }
        }
        vm.selectedReview = review;
        setupGroupByListview();
    }


    function resetReviews() {
        vm.selectedGroup = null;
        vm.selectedTile = null;
    }

    // my reviews list-view
    function setupMyReviewsListview() {
        setupToggle();
        toggleReviews('0');
    }

    function setupToggle() {
        if (vm.reviews.length > 0) {
            if (vm.reviews.some(review => review.Status === 'Completed' || review.Status === 'Incomplete')) {
                vm.showToggle = true;
            } else {
                vm.showToggle = false;
            }
        }
    }

    function toggleReviews(val) {
        if (val !== '1') {
            vm.filteredReviews = vm.reviews.filter(review => review.Status !== 'Completed' && review.Status !== 'Incomplete');
        } else {
            vm.filteredReviews = vm.reviews;
        }
        vm.filteredReviews = $filter('orderBy')(vm.filteredReviews, '-GeneratedDate');
    }

    async function openSubmitReviewModal(review, vm) {
        vm.review = review;
        let reviewModal = $uibModal.open({
            templateUrl: 'App/Components/Certifications/MyReviews/SubmitReviewModal/submitReviewModal.html',
            controller: submitReviewModalController,
            controllerAs: 'srmvm',
            backdrop: 'static',
            size: 'lg',
            resolve: {
                review: () => review
            }
        });

        try {
            await reviewModal.result;
            if (vm.selectedReview.HeaderId === review.HeaderId) {
                vm.selectedReview = null;
                vm.selectedGroup = null;
                vm.selectedUser = null;
            }
            activate();
        } catch {
            return;
        }
    }

    function exportReview(review) {
        var postData = {
            "PreferredTimeZone": $rootScope.applicationTimeZone
        };
        myReviewsDataService.exportReview(review.HeaderId, postData);
    }

    // group by list-view
    function setupGroupByListview() {
        vm.availableGroupingMethods = [
            {
                name: `Group Reviews by ${vm.reviewTypeName}`,
                type: 'ReviewType'
            },
            {
                name: 'Group Reviews by Users',
                type: 'User'
            }
        ];
        vm.selectedGroupingMethod = vm.availableGroupingMethods[0];
        setupGroupByList();
    }

    function resetValues() {
        vm.selectedGroup = null;
        vm.selectedUser = null;
        vm.searchQuery = '';
    }

    async function setupGroupByList() {
        vm.loadingGroupItems = true;

        resetValues();

        let groupingList = await myReviewsDataService.getReviewDetails(vm.selectedReview.ReviewType, vm.selectedReview.HeaderId, vm.selectedGroupingMethod.type);

        if (vm.selectedGroupingMethod.type === 'ReviewType') {
            buildTypeList(groupingList);
        } else if (vm.selectedGroupingMethod.type === 'User') {
            buildUserList(groupingList);
        }

    }

    function getItemId(reviewType) {
        switch (reviewType) {
            case "SecurityRole":
            case "SecurityRoleContent":
                return "SecurityRoleIdentifier";
                break;
            case "Product":
                return "ProductId";
                break;
            case "Risk":
                return "RiskId";
                break;
            case "BusinessProcess":
                return "BusinessProcessId";
                break;
            default:
                return null;
                break;
        }
    }

    function buildTypeList(groupItems) {

        groupItems = groupItems.map(obj => {
            if (vm.selectedReview.ReviewType === 'SecurityRoleContent') {
                obj.Remaining = obj.items.filter(item => !item.StatusId && item.MultiApprovalStatus !== "Complete").length;
                let itemTypes = obj.items.map(item => item.RoleContentType).unique();
                obj.RemainingCounts = [];
                itemTypes.forEach(type => {
                    let countObject = {
                        Type: type,
                        Remaining: obj.items.filter(item => !item.StatusId && item.RoleContentType === type && item.MultiApprovalStatus !== 'Complete').length,
                        ReviewedByOthers: obj.items.filter(item => item.RoleContentType === type && item.MultiApprovalStatus === 'Complete').length
                    };
                    obj.RemainingCounts.push(countObject);
                });
                // update counts for Review items
                if (vm.selectedGroup) {
                    vm.selectedGroup = { ...vm.selectedGroup };
                }
            } else {
                obj.Remaining = obj.Items.filter(item => !item.StatusId && item.MultiApprovalStatus !== "Complete").length;
            }
            if (vm.selectedReview.ReviewType === 'Risk') {
                obj.Risk = obj.Items[0].RiskLevel;
            }
            obj.ItemId = obj[getItemId(vm.selectedReview.ReviewType)];
            return obj;
        });

        vm.selectedReviewGroups = groupItems;

        vm.loadingGroupItems = false;
    }

    async function buildUserList(groupItems) {
        if (!vm.selectedUser) {
            vm.users = [];
        }

        vm.users = groupItems.map(obj => {
            obj.Remaining = obj.Items.filter(item => !item.StatusId && item.MultiApprovalStatus !== "Complete").length;
            obj.userDisplay = obj[vm.userDisplay];
            return obj;
        });

        vm.loadingGroupItems = false;

    }

    function selectedGroupChanged(object) {

        if (vm.selectedGroup === object || vm.processingItemStatus) return;
        vm.allFilteredUsers = null;
        vm.allTypes = null;
        vm.currentUsersPage = 1;
        vm.currentTypesPage = 1;

        let reviewType = vm.selectedReview.ReviewType;

        switch (reviewType) {
            case "Risk":
                vm.reviewDetailsSort = ["RiskName", "UserName"];
                break;
            case "BusinessProcess":
                vm.reviewDetailsSort = ["BusinessProcessName", "UserName"];
                break;
            case "Product":
                vm.reviewDetailsSort = ["ProductName", "UserName"];
                break;
            case "SecurityRole":
                vm.reviewDetailsSort = ["SecurityRoleName", "UserName"];
                break;
        }
        vm.selectedGroup = object;
        if (reviewType === 'SecurityRoleContent') {
            setupGroupBySecurityRoleTypeListview();
        } else {
            setupGroupByUserListview();
        }

    }

    function selectUser(object) {
        if (vm.processingItemStatus) return;
        vm.selectedUser = object;
        setupGroupByTypeListview();
    }

    // select user list-view
    async function setupGroupByUserListview() {
        if (vm.selectedGroup || vm.selectedUser) {
            await setupPermissions();
            filterUsers();
            vm.allItemStatus = checkAllStatus(vm.filteredUsers);
        }
    }

    async function setupGroupBySecurityRoleTypeListview() {
        if (vm.selectedGroup || vm.selectedUser) {
            await setupPermissions();
            vm.configValues = {
                showBPObject: vm.showRoleContentBPObject,
                showCAObject: vm.showRoleContentCAObject,
                showSelectAll: vm.showSelectAll
            }
        }
    }

    async function setupPermissions() {

        vm.loadingItems = true;

        let config = {};
        let values = await configurationDataService.getConfigurationValues();

        values.data.forEach(row => config[row.Name] = row.Value);

        vm.showSelectAll = config['Cert_AllowSelectAll'];
        vm.showBPObject = config['Cert_BusinessProcessObjects'];
        vm.showRoleContentBPObject = config['Cert_RoleContentBusinessProcessObjects'];
        vm.requireNotesOnRejectedItems = config['Cert_RequireNotesOnRejectedItems'] === '1' ? true : false;
        vm.loadingItems = false;
    }

    function filterUsers() {
        vm.loadingItems = true;
        let objects;

        if (vm.selectedGroup) {
            objects = vm.selectedGroup.Items;
        } else {
            objects = vm.selectedUser.Items;
        }

        vm.totalObjects = objects;

        vm.filteredUsers = objects;

        vm.filteredUsers.forEach(u => u.userDisplay = u[vm.userDisplay]);
        vm.usersRadioFilter = 'All';
        vm.showAssignedinAllCompaniesOnly = false;
        vm.loadingItems = false;
        vm.filteredUsers;
    }

    function changeSearchFilter() {
        $timeout(async _ => {
            vm.allItemStatus = checkAllStatus(vm.filteredUserItems);
        }, 1);
    }

    async function openNotesModal(item) {
        let itemNotesModal = $uibModal.open({
            templateUrl: 'App/Components/Certifications/MyReviews/notesModal/notesModal.tmpl.html',
            controller: notesModalController,
            controllerAs: 'nmvm',
            backdrop: 'static',
            resolve: {
                reviewItem: () => item,
                notesRequired: () => vm.requireNotesOnRejectedItems,
                allowCancel: () => true
            }
        });

        try {
            let itemNotes = await itemNotesModal.result;
            item.Notes = itemNotes;
            updateItemStatus(item, true);
        } catch {
            return;
        }
    }

    async function changeItemStatus(item) {
        if (vm.requireNotesOnRejectedItems && item.StatusId === 2) {
            let itemNotesModal = $uibModal.open({
                templateUrl: 'App/Components/Certifications/MyReviews/notesModal/notesModal.tmpl.html',
                controller: notesModalController,
                controllerAs: 'nmvm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    reviewItem: () => item,
                    notesRequired: () => vm.requireNotesOnRejectedItems,
                    allowCancel: () => false
                }
            });

            try {
                let itemNotes = await itemNotesModal.result;
                item.Notes = itemNotes;
            } catch {
                return;
            }
        }
        let filteredUsers = vm.filteredUsers.slice();
        filteredUsers.remove(item);

        if (!item.Notes) { item.Notes = "" }

        updateItemStatus(item);
    }

    async function updateItemStatus(item, notesOnly) {
        vm.processingItemStatus = true;

        if (item.StatusId === null && item.MultiApprovalStatus === 'Complete' && !notesOnly) {
            // need to set multiapproval status back to pending since reviewer is resetting their action
            item.MultiApprovalStatus = 'Pending';
        }

        let putBody = {
            "ReviewItems": []
        };

        putBody.ReviewItems.push({
            "HeaderId": item.HeaderId,
            "ItemId": item.ItemId,
            "Status": item.StatusId,
            "Notes": item.Notes
        });

        await myReviewsDataService.updateItemStatus(item.HeaderId, vm.selectedReview.ReviewType, putBody);
        await vm.getReviews();
        getUpdatedRemainingReviewItems();
        vm.processingItemStatus = false;
        vm.allItemStatus = checkAllStatus(vm.filteredUserItems);
    }

    async function updateAllItemStatuses(newValue) {
        if (vm.selectedGroupingMethod.type === 'User') {
            filterUsers();
        }
        vm.processingItemStatus = true;

        let putBody = {
            "ReviewItems": []
        };

        let users = vm.filteredUserItems;

        if (users.every(user => user.StatusId === newValue)) {
            newValue = null;
        }

        // if they require notes for rejected items we need to capture notes and apply them to all the rejected items
        if (newValue === 2 && vm.requireNotesOnRejectedItems) {
            let itemNotesModal = $uibModal.open({
                templateUrl: 'App/Components/Certifications/MyReviews/notesModal/notesModal.tmpl.html',
                controller: notesModalController,
                controllerAs: 'nmvm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    reviewItem: () => null,
                    notesRequired: () => vm.requireNotesOnRejectedItems,
                    allowCancel: () => false
                }
            });

            try {
                var itemNotes = await itemNotesModal.result;
            } catch {
                return;
            }
        }

        let filteredUsers = [...users];

        if (vm.selfReviewConfigValue === '3') {
            filteredUsers = filteredUsers.filter(row => !row.IsSelfReview);
        }

        if (filteredUsers.length > 0) {

            filteredUsers.forEach(row => {
                if (row.MultiApprovalStatus === 'Complete' && row.StatusId === null) {
                    return;
                }

                if (row.StatusId === null && row.MultiApprovalStatus === 'Complete' && newValue === null) {
                    row.MultiApprovalStatus = 'Pending';
                }

                row.StatusId = newValue;

                putBody.ReviewItems.push({
                    "HeaderId": row.HeaderId,
                    "ItemId": row.ItemId,
                    "Status": row.StatusId,
                    "Notes": itemNotes ? itemNotes : row.Notes
                });
                if (itemNotes) {
                    row.Notes = itemNotes;
                }
            });

            await myReviewsDataService.updateItemStatus(putBody.ReviewItems[0].HeaderId, vm.selectedReview.ReviewType, putBody);
            await vm.getReviews();
            getUpdatedRemainingReviewItems();
            vm.processingItemStatus = false;
            vm.allItemStatus = checkAllStatus(vm.filteredUserItems);
        } else {
            helperService.showErrorMessage("None of the selected items are eligible for review.");
            vm.allItemStatus = checkAllStatus(vm.filteredUserItems);
            vm.processingItemStatus = false;
        }
    }

    function updateItemNote(item) {
        updateItemStatus(item);
    }

    async function getUpdatedRemainingReviewItems() {
        if (vm.selectedGroup) {
            buildTypeList(vm.selectedReviewGroups);
        } else if (vm.selectedUser) {
            buildUserList(vm.users);
        }
        toggleReviews(vm.showAll);
    }

    // select type list-view
    async function setupGroupByTypeListview() {
        await setupPermissions();
        vm.typesRadioFilter = 'All';
        vm.allItemStatus = checkAllStatus(vm.selectedUser.Items);
    }


    async function changeTypeItemStatus(item) {
        if (vm.requireNotesOnRejectedItems && item.StatusId === 2) {
            let itemNotesModal = $uibModal.open({
                templateUrl: 'App/Components/Certifications/MyReviews/notesModal/notesModal.tmpl.html',
                controller: notesModalController,
                controllerAs: 'nmvm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    reviewItem: () => item,
                    notesRequired: () => vm.requireNotesOnRejectedItems,
                    allowCancel: () => false
                }
            });

            try {
                let itemNotes = await itemNotesModal.result;
                item.Notes = itemNotes;
            } catch {
                return;
            }
        }

        const types = vm.selectedUser.Items.slice();
        types.remove(item);

        if (!item.Notes) { item.Notes = "" }

        updateItemStatus(item);
        vm.allItemStatus = checkAllStatus(vm.selectedUser.Items);

    }

    async function openDelegateModal(delegationContext) {
        vm.delegatePending = true;
        let delegationItems = [];
        if (delegationContext.Types) {
            delegationItems = delegationContext.Types.filter(type => type.StatusId === null);
        } else if (delegationContext.Items) {
            delegationItems = delegationContext.Items.filter(item => item.StatusId === null);
        } else if (delegationContext.items) {
            delegationItems = delegationContext.items.filter(item => item.StatusId === null);
        } else {
            let response = await myReviewsDataService.getReviewDetails(delegationContext.ReviewType, delegationContext.HeaderId, "ReviewType");
            response = response.reduce((acc, curr) => {
                if (curr.Items) {
                    return acc.concat(curr.Items);
                } else {
                    return acc.concat(curr.items);
                }
            }, []);
            delegationItems = response.filter(detail => detail.StatusId === null);
        }

        let delegateModal = $uibModal.open({
            templateUrl: '/App/Components/Certifications/MyReviews/DelegationModal/reviewDelegationModal.html',
            controller: reviewDelegationModalController,
            controllerAs: 'rdmvm',
            backdrop: 'static',
            resolve: {
                delegationItems: () => delegationItems,
                reviewHeaderId: () => delegationContext.HeaderId ? delegationContext.HeaderId : vm.selectedReview.HeaderId,
                reviewType: () => delegationContext.ReviewType ? delegationContext.ReviewType : vm.selectedReview.ReviewType
            }
        });

        try {
            await delegateModal.result;
            vm.delegatePending = false;
        } catch { vm.delegatePending = false; }
    }

    function radioFilterChanged() {
        $timeout(async _ => {
            vm.allItemStatus = checkAllStatus(vm.filteredUserItems);
        }, 1);
    }

    function checkAllStatus(reviewItems) {
        if (reviewItems.length > 0) {
            if (reviewItems.every(item => item.StatusId === 1)) {
                return '1';
            } else if (reviewItems.every(item => item.StatusId === 2)) {
                return '2';
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    async function updateReviewCounts() {
        await vm.getReviews();
        getUpdatedRemainingReviewItems();
    }

    async function openTicketModal(review) {
        $uibModal.open({
            templateUrl: '/App/Components/Certifications/MyReviews/TicketModal/ticketModal.tmpl.html',
            controller: ticketModalController,
            controllerAs: 'tmvm',
            backdrop: 'static',
            size: 'lg',
            resolve: {
                review: () => review
            }
        });
    }

}
