import availableTimeZones from "../../Shared/GlobalVariables/timezones";
import createSpinner from "../../Shared/Functions/createSpinner";
import { standardGridPageSize } from "../../Shared/GlobalVariables/filterVariables";
import { configurationOptions } from "./configOptions";

export default /*@ngInject*/ function (dataService, configurationDataService, $filter, $rootScope, helperService) {
    const vm = this;

    vm.helperService = helperService;
    vm.tabSelected = 'SystemSettings';
    vm.ssChildTabSelected = 'Company Information';
    vm.updatePending = false;
    vm.currentTenant = angular.fromJson(localStorage.getItem("selectedTenant"));
    vm.putConfigurationSetting = putConfigurationSetting;
    vm.configValuesHaveChanged = configValuesHaveChanged;
    vm.submitAccessRiskMonitor = submitAccessRiskMonitor;
    vm.submitCompanyInfo = submitCompanyInfo;
    vm.submitSecurityDesigner = submitSecurityDesigner;
    vm.submitSystemConfiguration = submitSystemConfiguration;
    vm.submitAccessCertifications = submitAccessCertifications;
    vm.configNames = configurationOptions.configNames;
    vm.showPasswordCriteria = showPasswordCriteria;
    vm.deleteCompanyLogo = deleteCompanyLogo;
    vm.reset = reset;

    activate();

    async function activate() {
        getExistingCompanyLogo();
        await getProducts();
        await getConfigValues();
        vm.s4Enabled = vm.availableProducts.SAP ? await getS4Config() : false;
        await setupRolesDropdown();

        await getIdentityProviders();

        setAuditTrailTabVisibility();
        setupTimeZones();

    }

    async function getConfigValues() {
        let response = await configurationDataService.getConfigurationValues(),
            config = {};

        response.data.forEach(row => {
            config[row.Name] = row.Value;
        });

        // Need to set up these config values as numbers now so they don't enable save button
        if (vm.productsDataSource.some(product => product.Type === 'SAP')) {
            config.MaxFirefighterAccessPeriod = Number(config.MaxFirefighterAccessPeriod);
        }
        if (config.IM_ReminderThreshold) {
            config.IM_ReminderThreshold = Number(config.IM_ReminderThreshold);
        }
        if (config.IM_DaysBeforeExpirationReminder) {
            config.IM_DaysBeforeExpirationReminder = Number(config.IM_DaysBeforeExpirationReminder);
        }
        if (config.IM_AutomaticDelegationDaysThreshold) {
            config.IM_AutomaticDelegationDaysThreshold = Number(config.IM_AutomaticDelegationDaysThreshold);
        }

        if (config.IM_DefaultExpirationEnabled === '1' && config.IM_DefaultExpirationDays) {
            config.IM_DefaultExpirationDays = Number(config.IM_DefaultExpirationDays);
        }

        if (config.AutogeneratedUserRoles) {
            config.AutogeneratedUserRoles = config.AutogeneratedUserRoles.replaceAll(";", ",");
            vm.selectedRoleIds = config.AutogeneratedUserRoles.split(",").map(role => Number(role));
        }

        vm.config = JSON.parse(JSON.stringify(config));
        vm.origConfig = JSON.parse(JSON.stringify(config));
        vm.configValuesDiffer = false;
    }

    async function getS4Config() {
        const { data } = await configurationDataService.getProductConfigurationValueByName('SAP', 'SAP_ErpType');
        return data.Value === "S4";
    }

    async function getProducts() {
        let { data } = await dataService.getProducts();

        vm.availableProducts = {};

        data.forEach(product => {
            vm.availableProducts[product.Type] = true;
        });

        vm.productsDataSource = data;
    }

    async function setupRolesDropdown() {
        vm.rolesDropdownOptions = {
            filter: "contains",
            autoClose: false,
            virtual: {
                itemHeight: 26,
                mapValueTo: 'dataItem',
                valueMapper: options => {
                    options.success(helperService.multiValueMapperFunction(options, vm.rolesDataSource, 'ID'));
                }
            },
            height: 325,
            placeholder: `-- Select one or more Roles --`
        };

        vm.rolesDropdownDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: async (options) => {
                    vm.rolesDropdownLoading = true;
                    let { data } = await dataService.getRoles();
                    vm.rolesDataSource = $filter('orderBy')(data, 'Name');
                    options.success(vm.rolesDataSource);
                    vm.rolesDropdownLoading = false;
                }
            }
        });
    }

    async function getIdentityProviders() {
        try {
            let { data } = await configurationDataService.getIdentityProviders();
            vm.providersList = data;
            vm.providersList = $filter('orderBy')(vm.providersList, 'Name');
        } catch {
            return;
        }
    }

    async function importCompanyLogo(file) {
        let fd = new FormData();

        vm.updatePending = new createSpinner();
        fd.append("ImageImport", file[0].file);

        try {
            await configurationDataService.importCompanyLogo(fd);
            helperService.successfulSaveButton(vm.updatePending);
            $rootScope.$broadcast('logoUpdated');
            vm.files.cancel();
            getExistingCompanyLogo();
        }
        catch {
            vm.updatePending.loadingValue = false;
        }
    }

    async function getExistingCompanyLogo() {
        vm.logoProcessing = true;
        try {
            const { data } = await configurationDataService.getCompanyLogo();
            vm.companyLogo = data;
            vm.logoProcessing = false;
        } catch {
            vm.companyLogo = null;
            vm.logoProcessing = false;
        }

    }

    async function deleteCompanyLogo() {
        vm.logoProcessing = true;
        await configurationDataService.deleteCompanyLogo();
        getExistingCompanyLogo();
        $rootScope.$broadcast('logoUpdated');
        vm.logoProcessing = false;
    }

    function setAuditTrailTabVisibility() {
        // Check if user has security for Audit Trail
        if (angular.fromJson(localStorage.getItem("NavigationObjects")).some(object => object.ObjectID === 3000)) {
            // Check if user has one product and that one product is AX7
            if (vm.productsDataSource.length === 1 && vm.productsDataSource[0].ID === 'AX7') {
                // If AX7 only - do not show Audit Trail tab
                vm.showAuditTrailTab = false;
            } else {
                vm.showAuditTrailTab = true;
            }
        } else {
            vm.showAuditTrailTab = false;
        }
    }

    function setupTimeZones() {
        //Delivery System TimeZone
        vm.availableTimeZones = availableTimeZones;
    }

    async function putConfigurationSetting(list, value, origVal) {
        let putData = {
            "Configurations": []
        };

        list.forEach(item => {
            if (value[item.Name] !== origVal[item.Name]) {
                putData.Configurations.push({
                    "Name": item.Name,
                    "Value": value[item.Name]
                });
            }
        });

        vm.updatePending = new createSpinner();

        try {
            await configurationDataService.updateConfigurationValues(putData);
            helperService.successfulSaveButton(vm.updatePending);
            putData.Configurations.forEach(config => {
                origVal[config.Name] = config.Value;
            });
        } catch {
            vm.updatePending.loadingValue = false;
        }
    }

    function configValuesHaveChanged(configArray) {
        return helperService.returnTrueIfValuesDiffer(configArray, vm.origConfig, vm.config);
    }

    function submitCompanyInfo(files) {
        let { companyInfo } = configurationOptions;
        if (vm.configValuesHaveChanged(['CompanyName', 'PrimarySystem', 'SystemTimeZone', 'EmailNewFpAdUsers'])) {
            vm.putConfigurationSetting(companyInfo, vm.config, vm.origConfig);
        }
        if (files.length) {
            importCompanyLogo(files);
        }
    }

    function submitSystemConfiguration() {
        let { systemConfigurations } = configurationOptions;

        vm.config.AutogeneratedUserRoles = ([vm.selectedRoleIds].join(", ")).replaceAll(',', ';');
        vm.putConfigurationSetting(systemConfigurations, vm.config, vm.origConfig); 
        vm.userForm.$setPristine();
    }

    function submitAccessRiskMonitor() {
        let list = configurationOptions.productOptions.default,
            productOptions = configurationOptions.productOptions;

        if (vm.availableProducts.PS) {
            list = [...list, ...productOptions.PS];
        } else if (vm.availableProducts.ORFC) {
            list = [...list, ...productOptions.ORFC];
        } else if (vm.availableProducts.OR) {
            list = [...list, ...productOptions.OR];
        } else {
            list = [...list, ...productOptions.SAP];
        }

        vm.putConfigurationSetting(list, vm.config, vm.origConfig);
    }

    function submitAccessCertifications() {
        let { accessCertifications } = configurationOptions;
        vm.putConfigurationSetting(accessCertifications, vm.config, vm.origConfig);
    }

    function submitSecurityDesigner() {
        vm.putConfigurationSetting([{ Name: "SD_RequireAnalysisForSimulations", Encrypted: false }], vm.config, vm.origConfig);
    }

    function showPasswordCriteria() {
        return vm.availableProducts?.SAP || vm.availableProducts?.AAD || vm.availableProducts?.PS;
    }

    function reset() {
        vm.imChildTabSelected,
        vm.scChildTabSelected, 
        vm.ssChildTabSelected,
        vm.acChildTabSelected = null;
    }
}