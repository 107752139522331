import allSupportedProducts from "../../Shared/GlobalVariables/ProductLookUp";
import newProductController from "./newProductModal.controller";

export default /*@ngInject*/ function ($scope, $uibModal, $rootScope, helperService, configurationDataService, connectionsDataService) {
    const vm = this,
        sortedProducts = allSupportedProducts.sort(sortByName);

    vm.$onInit = init;

    vm.getConnections = getConnections;
    vm.getProductImageClass = getProductImageClass;
    vm.helperService = helperService;
    vm.userSecurityList = $rootScope.UserSecurityList;
    vm.setRadioSelected = setRadioSelected;
    vm.openNewProductModal = openNewProductModal;

    async function init() {
        vm.radioSelected = '';
        vm.customProducts = [];
        vm.ProductImportTiles = {};
        vm.ProductImportTilesArray = [];
        vm.latestImportArray = [];
        vm.config = {};
        vm.origConfig = {};
        vm.checkImportFocus = checkImportFocus;

        function checkImportFocus (productId) {
            const radios = [productId + "_Import", productId + "_Settings", productId + "_Schedule"];
            let focused = true;

            if (productId != null) {
                if (!vm.radioSelected || radios.includes(vm.radioSelected)) {
                    focused = false;
                }
            }

            return focused;
        };

        sortedProducts.forEach(setImportTiles);

        await vm.getConnections();

        determineProductVisibility();

        await callLatest();

        $scope.$on('ProductImport', () => callLatest());
        $scope.$on('productImportInProgress', (event, data) => triggerProductImportInProgressStatus(data));

        await setConfigValues();
        await getHybridConnections();
    }

    function getProductImageClass(productType) {
        return helperService.setProductImageClass(productType);
    }

    function setImportTiles(product) {
        vm.ProductImportTiles[product.ID] = 'hide';
        vm.ProductImportTilesArray.push({ ProductName: product.Name, ProductID: product.ID, ProductType: product.Type, Visibility: 'hide', ...product });
    }

    async function setConfigValues() {
        let response = await configurationDataService.getConfigurationValues(),
            config = {};

        response.data.forEach(row => {
            config[row.Name] = row.Value;
        });

        //Hard-code encrypted
        config['SAP_Password'] = 'password';
        config['OR_Password'] = 'password';
        config['AX7_AppSecret'] = 'password';
        config['D365S_Password'] = 'password';
        config['D365S_ClientSecret'] = 'password';
        config['D365Bc_ClientSecret'] = 'password';
        config['D365Bc_Key'] = 'password';
        config['D365Bc_Password'] = 'password';
        config['NS_TokenKey'] = 'password';
        config['NS_TokenSecret'] = 'password';
        config['INT_Password'] = 'password';
        config['INT_ConsolePassword'] = 'password';
        config['INT_GatewayPassword'] = 'password';
        config['GP_Password'] = 'password';
        config['NAV_Password'] = 'password';
        config['NAV_WSPassword'] = 'password';
        config['AX_SqlPassword'] = 'password';
        config['SF_SecurityToken'] = 'password';
        config['SF_Password'] = 'password';
        config['SF_ClientSecret'] = 'password';
        config['AC_Password'] = 'password';
        config['PS_Password'] = 'password';
        config['PS_IM_Password'] = 'password';
        config['ORFC_Password'] = 'password';
        config['AC_SqlPassword'] = 'password';
        config['JDE_Password'] = 'password';
        config['WD_Password'] = 'password';
        config['AAD_ClientSecret'] = 'password';
        config['CPA_ApiKey'] = 'apikey';
        config['CPA_ClientSecret'] = 'password';
        config['SapGrc_Password'] = 'password';
        config['Zuo_ClientSecret'] = 'password';
        config['AZ_ClientSecret'] = 'password';
        config['ARB_ApiKey'] = 'password';
        config['ARB_Base64ClientSecret'] = 'password';
        config['CNC_ClientSecret'] = 'password';
        config['CNC_CompanyPassword'] = 'password';
        config['ADO_PersonalAccessToken'] = 'password';
        config['WD_ClientSecret'] = 'password';

        vm.config = JSON.parse(JSON.stringify(config));
        vm.origConfig = JSON.parse(JSON.stringify(config));
    }

    async function getHybridConnections() {
        const response = await connectionsDataService.getHybridConnections();

        vm.hybridConnectionsArray = response.data;
    }

    function setRadioSelected(event, radio) {
        if (event && event.currentTarget.classList.contains("active")) {
            vm.radioSelected = radio;
        } else {
            vm.radioSelected = '';
        }
    }

    async function callLatest() {
        const response = await connectionsDataService.getLatestProductImports();

        vm.latestImportArray = response.data || [];
    }

    function triggerProductImportInProgressStatus(importingProduct) {
        if (vm.latestImportArray.length > 0) {
            let productIndex = vm.latestImportArray.findIndex(product => product.ProductType === importingProduct.ProductType && product.ProductId === importingProduct.ProductId);

            vm.latestImportArray[productIndex].Status = 'InProgress';
            vm.latestImportArray[productIndex].StartDate = new Date();
        }
    }

    async function getConnections() {
        let response = await connectionsDataService.getProducts(),
            data = response.data;

        vm.availableProducts = data;
        vm.customProducts = [];
        vm.sapProducts = [];

        for (let product of vm.availableProducts) {
            const supportedProduct = sortedProducts.find(supportedProduct => supportedProduct.ID === product.ID || (supportedProduct.Type === "SAP" && product.Type === "SAP")),
                isCustomProduct = !sortedProducts.find(supportedProduct => supportedProduct.ID === product.ID),
                isSap = product.Type === "SAP",
                isSql = product.Type === "AX5" || product.Type === "SL";

            if (isCustomProduct && !isSap) {
                product.isCustom = true;
                await getCustomProduct(product);
            }

            if (isSql) {
                await getSqlProduct(product);
            }

            if (isSap) {
                product.isSap = true;
                await getSapProduct(Object.assign({}, supportedProduct, product));
            }
        };
    }

    async function getCustomProduct(product) {
        let response;
        
        try {
            response = await connectionsDataService.getConnectionSql(product.ID);

            product.CustomProduct_User = response.data.UserName;
            product.CustomProduct_DatabaseName = response.data.DatabaseName;
            product.CustomProduct_Instance = response.data.Instance;
            product.CustomProduct_HostName = response.data.HostName;
            product.CustomProduct_Port = response.data.Port;
            product.CustomProduct_Password = 'fastpath';
            product.ConnectionExists = true;

            vm.customProducts.push(product);
        } catch {
            product.ConnectionExists = false;
            vm.customProducts.push(product);
        }

        await getProductSchedule(product);
    }

    async function getSqlProduct(product) {
        let response;

        try {
            response = await connectionsDataService.getProductSql(product.ID);

            product.SQL_User = response.data.UserName;
            product.SQL_DatabaseName = response.data.DatabaseName;
            product.SQL_Instance = response.data.Instance;
            product.SQL_HostName = response.data.HostName;
            product.SQL_Port = response.data.Port;
            product.SQL_Password = "password";
            product.ConnectionExists = true;
        } catch {
            product.ConnectionExists = false;
        }

        await getProductSchedule(product);
    }

    async function getProductSchedule(product) {
        let response;

        try {
            response = await connectionsDataService.getProductSchedule(product.ID);
            product.importSchedule = response.data;
            product.selectedFreqForScheduleImport = response.data.Frequency;
            product.selectedDayOfWeekForScheduleImport = response.data.DayOfWeek;
            product.selectedDayOfMonthForScheduleImport = { Value: response.data.DayOfMonth };
            product.startTimeForScheduleImport = new Date(response.data.StartTime);
            product.ScheduleImportIsOn = true;
        } catch {
            product.importSchedule = null;
            product.ScheduleImportIsOn = false;
        }
    }

    async function getSapProduct(product) {
        let response,
            sapProduct;

        try {
            response = await connectionsDataService.getSapProduct(product.ID);

            sapProduct = Object.assign({}, product, response.data);

            sapProduct.ConnectionExists = true;
            sapProduct.originalConfig = response.data;
        } catch {
            sapProduct = product;
            sapProduct.originalConfig = {};
            sapProduct.ConnectionExists = false;
        }

        vm.sapProducts.push(sapProduct);
    }

    function determineProductVisibility() {
        vm.availableProducts.forEach(availableProduct => {
            vm.ProductImportTiles[availableProduct.ID] = 'show';
            let selectedProduct = vm.ProductImportTilesArray.filter(product => product.ProductID == availableProduct.ID).first();

            if (selectedProduct) {
                selectedProduct.Visibility = 'show';
            }

            selectedProduct = Object.assign(selectedProduct || {}, availableProduct);
        });
    }

    async function openNewProductModal() {
        const productModal = $uibModal.open({
            templateUrl: '/App/Shared/Partials/NewProductModal.html',
            controller: newProductController,
            controllerAs: 'npcvm',
            backdrop: 'static',
            resolve: {
                newProduct: () => { }
            }
        });

        try {
            const newId = await productModal.result;

            if (newId) {
                vm.getConnections();
            }
        } catch { }
    }
}

function sortByName(a, b) {
    return a.Name.localeCompare(b.Name)
}