import substituteModalController from "../../Components/Users/SubstituteModal/substituteModal.controller";

export default /*@ngInject*/ function ($scope, $rootScope, $filter, $window, helperService, $uibModal, $http, applicationInsightsService, risksDataService, dataService) {

    var vm = $scope.vm = {};

    $scope.isAutoCriteriaCollapsed = true;
    $scope.OOFManagerCollapsed = true;
    $scope.objectSearchFieldCollapsed = true;
    $scope.preferredCompanyFieldCollapsed = true;
    $scope.preferredProductFieldCollapsed = true;
    $scope.settingsList = [];

    var Setting = function (params) {
        this.Name = params.Name;
        this.Type = params.Type;
        this.Description = params.Description;
        this.IsAutoCriteriaCollapsed = params.IsAutoCriteriaCollapsed;
    };



    //When the UserPreferenceList is populated in app.report.rootViewModel then populate the settings list for the settings flyout
    $rootScope.$watch('UserPreferenceList', function () {
        getProducts();
        if ($rootScope.UserPreferenceList != null && $scope.settingsList.length == 0) {
            $rootScope.UserPreferenceList.forEach(function (pref) {
                $scope.settingsList.push(
                    new Setting({
                        Name: pref.Name,
                        Type: pref.Type,
                        Description: pref.Description,
                        IsAutoCriteriaCollapsed: true
                    })
                );
            });
        }

        if ($rootScope.UserPreferenceList) {
            $rootScope.UserPreferenceList.forEach(function (setting) {
                if (setting.Type === 'ObjectSearchField') {
                    $scope.searchObject = setting.Value;
                }
                 
                if (setting.Type === 'PreferredCompany') {
                    $scope.preferredCompany = setting.Value;
                }

                if (setting.Type === 'PreferredProduct') {
                    $scope.preferredProduct = setting.Value;
                }
            });
            getCompanies();
        }

    });

    $scope.helperService = helperService;

    async function getCompanies() {
        let response = await risksDataService.getCompanies();
        response.data = $filter('orderBy')(response.data, 'Name');
        $scope.noCompanyDefaultDropdownObject = { "Name": "No Default Company", "ID": null };
        response.data = response.data.filter(c => c.UserHasAccess === true);
        response.data.unshift($scope.noCompanyDefaultDropdownObject);
        $scope.defaultCompanySelected = response.data.find(obj => obj.Name === $scope.preferredCompany);
        let indexOfDefaultCompanySelected = response.data.indexOf($scope.defaultCompanySelected);
        if ($scope.preferredCompany !== null) {
            $scope.selectedCompany = response.data[indexOfDefaultCompanySelected];
        } else {
            $scope.selectedCompany = response.data[0];
        }
        $scope.companyDataSource = response.data;
    }

    async function getProducts() {
        let response = await dataService.getProducts();
        $scope.productsDataSource = response.data;
        if ($scope.productsDataSource.length === 1) {
            $scope.checkForMultipleProducts = false;
        } else {
            $scope.checkForMultipleProducts = true;
        }
        $scope.productsDataSource = $filter('orderBy')($scope.productsDataSource, 'Name');
        $scope.noDefaultProductDropdownObject = { "Name": "No Default Product", "isCustom": false, "ID": null }; 
        $scope.productsDataSource.unshift($scope.noDefaultProductDropdownObject);
        $scope.defaultProductSelected = $scope.productsDataSource.find(obj => obj.Name === $scope.preferredProduct);
        let indexOfDefaultProductSelected = $scope.productsDataSource.indexOf($scope.defaultProductSelected);
        if ($scope.productsDataSource.length > 0) {
            if ($scope.preferredProduct !== null) {
                $scope.selectedProduct = $scope.productsDataSource[indexOfDefaultProductSelected];
            } else {
                $scope.selectedProduct = $scope.productsDataSource[0];
            }
        }
    }

    //If right side bar closes minimize all the descriptions
    $rootScope.$watch('rightSidebar', function () {
        if ($rootScope.rightSidebar == false) {
            if ($scope.settingsList != null) {
                $scope.settingsList.forEach(function (setting) {
                    setting.IsAutoCriteriaCollapsed = true;
                });
            }
        }
    });

    $scope.showHideDescription = function (passedSetting) {
        $scope.settingsList.forEach(function (setting) {
            if (setting.Value != passedSetting.Value) {
                setting.IsAutoCriteriaCollapsed = true;
            }
        });
        passedSetting.IsAutoCriteriaCollapsed = !passedSetting.IsAutoCriteriaCollapsed;
    };

    $scope.showHideOOFManagerDescription = function () {
        $scope.OOFManagerCollapsed = !$scope.OOFManagerCollapsed;
    };

    $scope.showHideObjectSearchFieldDescription = function () {
        $scope.objectSearchFieldCollapsed = !$scope.objectSearchFieldCollapsed;
    };

    $scope.showHidePreferredCompanyFieldDescription = function () {
        $scope.preferredCompanyFieldCollapsed = !$scope.preferredCompanyFieldCollapsed;
    };

    $scope.showHidePreferredProductFieldDescription = function () {
        $scope.preferredProductFieldCollapsed = !$scope.preferredProductFieldCollapsed;
    };

    $scope.objectSearchFieldButton = function (searchFieldSelected) {
        if (searchFieldSelected === true) {
            $scope.searchObject = 'AltName';
        } else {
            $scope.searchObject = 'Name';
        }
    }

    $scope.openOOFManagerModal = function () {
        $uibModal.open({
            templateUrl: '/App/Components/Users/SubstituteModal/substituteModal.html',
            controller: substituteModalController,
            controllerAs: 'smvm',
            backdrop: 'static',
            resolve: {
                user: () => null,
                source: () => 'userPreferences'
            }
        });
    };

    $scope.notificationFunction = function (archive) {
        if (archive.options) {
            if (archive.options.onTap) {
                archive.options.onTap(archive);
            }
        }
    };

    $scope.closeArchivedNotification = function (archive) {
        $rootScope.archivedNotifications.remove(function (n) {
            return n == archive;
        });
    };

    $scope.clearAllArchivedNotifications = function (archive) {
        $rootScope.archivedNotifications = [];
    };

    $rootScope.toggleSetting = function (name, value) {
        var data = {
            "Name": name,
            "Value": value
        };

        applicationInsightsService.trackEvent('UserPreference', { Name: name, Value: value });
        $http.put(apiUrl + 'api/core/userpreferences/' + name, data)
            .then(function (response) {
                $rootScope.$broadcast('userpreferences-change');

                //Code to hide/show the tab viewer when the user changes the ShowReportTabs preference
                if (name == "ShowReportTabs") {
                    var enabled = value == "0" ? false : true;

                    if (enabled == false) {
                        $rootScope.tabs = [];
                    }

                    if ($rootScope.currentRoute) {
                        if ($rootScope.currentRoute.data == null) {
                            //Do nothing, the route is loaded but there isn't a data tag so most likely it isn't a report
                        }
                        else if ($rootScope.currentRoute.data.IsPlaceReport != null && $rootScope.currentRoute.data.IsPlaceReport == true) {
                            $rootScope.IsReport = enabled;
                            if (enabled == true && $rootScope.tabs.length == 0) {
                                $window.location.href = $rootScope.previousURL;
                            }
                        }
                        else if ($rootScope.currentRoute.data.IsReport != null && $rootScope.currentRoute.data.IsReport == true) {
                            $rootScope.IsReport = enabled;
                            $rootScope.tabChangedEventFired = true;
                            if (enabled == true && $rootScope.tabs.length == 0) {
                                $window.location.href = $rootScope.previousURL;
                            }
                        }
                    }
                    else {
                        //If the current route isn't set change the report tab hide/show value
                        $rootScope.IsReport = enabled;
                    }
                }
            });

    };

    $scope.updateValue = function (name, value) {
        if (value === "No Default Company" || value === "No Default Product") {
            value = null;
        }
        var data = {
            "Name": name,
            "Value": value
        };

        applicationInsightsService.trackEvent('UserPreference', { Name: name, Value: value });
        $http.put(apiUrl + 'api/core/userpreferences/' + name, data)
            .then(function (response) {
                $rootScope.$broadcast('userpreferences-change');
            });

    };

}