import createSpinner from "../../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function (helperService, connectionsDataService) {
    const vm = this;

    vm.$onInit = init;

    vm.updateCustomProduct = updateCustomProduct;

    function init() {
        vm.product.updatePassword = false;
    }

    async function updateCustomProduct() {
        const product = vm.product;
        let data = {
            ProductId: product.ID,
            DatabaseName: product.CustomProduct_DatabaseName,
            HostName: product.CustomProduct_HostName,
            Instance: product.CustomProduct_Instance,
            Port: product.CustomProduct_Port,
            Password: product.CustomProduct_Password,
            UserName: product.CustomProduct_User
        };

        vm.updatePending = true;
        vm.updatePending = new createSpinner();

        if (product.ConnectionExists === true) {
            if (!product.updatePassword) {
                data["Password"] = null;
            }

            try {
                await connectionsDataService.updateSqlConfig(product.ID, data);
                helperService.successfulSaveButton(vm.updatePending);
            } catch (response) {
                vm.updatePending.loadingValue = false;
            }
        } else {
            try {
                await connectionsDataService.createSqlConfig(product.ID, data);
                helperService.successfulSaveButton(vm.updatePending);
                product.ConnectionExists = true;
            } catch (response) {
                vm.updatePending.loadingValue = false;
            }
        }
    }
}
     
