const allSupportedProducts = [
    {
        Name: 'NetSuite',
        ID: 'NS',
        Type: 'NS',
        LogoClass: 'netSuite-avatar-logo',
        SecurityId: '16300',
        Config: [
            { Name: "NS_Account", Encrypted: false },
            { Name: "NS_InactiveRoles", Encrypted: false },
            { Name: "NS_InactiveUsers", Encrypted: false },
            { Name: "NS_ImportUserNameOnly", Encrypted: false },
            { Name: "NS_TokenKey", Encrypted: true },
            { Name: "NS_TokenSecret", Encrypted: true }
        ]
    },
    {
        Name: 'Salesforce',
        ID: 'SF',
        Type: 'SF',
        LogoClass: 'salesforce-avatar-logo',
        SecurityId: '18300',
        Config: [
            { Name: "SF_ImportInactive", Encrypted: false },
            { Name: "SF_ImportMetadata", Encrypted: false },
            { Name: "SF_Password", Encrypted: true },
            { Name: "SF_SecurityToken", Encrypted: true },
            { Name: "SF_UserName", Encrypted: true },
            { Name: "SF_Url", Encrypted: false },
            { Name: "SF_ClientId", Encrypted: false },
            { Name: "SF_ImportType", Encrypted: false },
            { Name: "SF_ClientSecret", Encrypted: true }

        ]
    },
    {
        Name: 'Dynamics 365 for Finance and Operations',
        ID: 'AX7',
        Type: 'AX7',
        LogoClass: 'dynamics-365-avatar-logo',
        SecurityId: '4300',
        Config: [
            { Name: "AX7_ActiveDirectoryClientAppID", Encrypted: false },
            { Name: "AX7_ActiveDirectoryTenant", Encrypted: false },
            { Name: "AX7_EnvironmentUrl", Encrypted: false },
            { Name: "AX7_AppSecret", Encrypted: true },
            { Name: "AX7_AS_ShowDisabledUsers", Encrypted: false },
            { Name: "AX7_AS_ImportDisabledUserAccess", Encrypted: false }
        ]
    },
    {
        Name: 'Dynamics 365 Customer Engagement',
        ID: 'D365S',
        Type: 'D365S',
        LogoClass: 'dynamics-365-sales-avatar-logo',
        SecurityId: '26300',
        Config: [
            { Name: "D365S_AuthType", Encrypted: false },
            { Name: "D365S_Password", Encrypted: true },
            { Name: "D365S_Url", Encrypted: false },
            { Name: "D365S_UserName", Encrypted: false },
            { Name: "D365S_Organization", Encrypted: false },
            { Name: "D365S_ProductSKU", Encrypted: false },
            { Name: "D365S_ImportDisabledUsers", Encrypted: false },
            { Name: "D365S_ClientId", Encrypted: false },
            { Name: "D365S_ClientSecret", Encrypted: true },
            { Name: "D365S_ApiUri", Encrypted: false },
            { Name: "D365S_Domain", Encrypted: false }
        ],
        AuthTypeOptions: [
            { Id: "1", Name: "AAD" },
            { Id: "2", Name: "AD" },
            { Id: "3", Name: "IFD" }
        ]
    },
    {
        Name: 'Dynamics GP',
        ID: 'GP',
        Type: 'GP',
        LogoClass: 'gp-avatar-logo',
        SecurityId: '22300',
        Config: [
            { Name: "GP_UseConfigAD", Encrypted: false },
            { Name: "GP_Database", Encrypted: false },
            { Name: "GP_HostName", Encrypted: false },
            { Name: "GP_Instance", Encrypted: false },
            { Name: "GP_Port", Encrypted: false },
            { Name: "GP_Version", Encrypted: false },
            { Name: "GP_User", Encrypted: false },
            { Name: "GP_Password", Encrypted: true },
            { Name: "GP_InactiveUsers", Encrypted: false }
        ],
        VersionOptions: [
            { Id: "0", Name: "GP 9" },
            { Id: "1", Name: "GP 10" },
            { Id: "2", Name: "GP 2010" },
            { Id: "3", Name: "GP 2013" },
            { Id: "4", Name: "GP 2013 R2" },
            { Id: "5", Name: "GP 2015" },
            { Id: "7", Name: "GP 2015 R2" },
            { Id: "6", Name: "GP 2016" },
            { Id: "8", Name: "GP 2016 R2" },
            { Id: "9", Name: "GP 2018" },
            { Id: "10", Name: "GP 2018 R2" }
        ]
    },
    {
        Name: 'Intacct',
        ID: 'INT',
        Type: 'INT',
        LogoClass: 'intacct-avatar-logo',
        SecurityId: '15300',
        Config: [
            { Name: "INT_RoleBasedSecurity", Encrypted: false },
            { Name: "INT_BusinessUser", Encrypted: false },
            { Name: "INT_CRMUser", Encrypted: false },
            { Name: "INT_EmployeeUser", Encrypted: false },
            { Name: "INT_PlatformUser", Encrypted: false },
            { Name: "INT_ProjectManagerUser", Encrypted: false },
            { Name: "INT_Inactive", Encrypted: false },
            { Name: "INT_UserNameLabel", Encrypted: false },
            { Name: "INT_CompanyID", Encrypted: false },
            { Name: "INT_UserID", Encrypted: false },
            { Name: "INT_SenderID", Encrypted: false },
            { Name: "INT_Password", Encrypted: true },
            { Name: "INT_LockedOut", Encrypted: false },
            { Name: "INT_GatewayPassword", Encrypted: true },
            { Name: "INT_HasConsole", Encrypted: false },
            { Name: "INT_ConsoleCompanyID", Encrypted: false },
            { Name: "INT_ConsolePassword", Encrypted: true },
            { Name: "INT_ConsoleUserID", Encrypted: false }
        ]
    },
    {
        Name: 'Oracle',
        ID: 'OR',
        Type: 'OR',
        LogoClass: 'oracle-avatar-logo',
        SecurityId: '17304',
        Config: [
            { Name: "OR_HostName", Encrypted: false },
            { Name: "OR_ServiceName", Encrypted: false },
            { Name: "OR_Port", Encrypted: false },
            { Name: "OR_User", Encrypted: false },
            { Name: "OR_Password", Encrypted: true },
            { Name: "OR_RBACEnabled", Encrypted: false },
            { Name: "OR_BreakoutByOrg", Encrypted: false },
            { Name: "OR_IncludeInvOrgs", Encrypted: false }
        ]
    },
    {
        Name: 'SAP',
        ID: 'SAP',
        Type: 'SAP',
        LogoClass: 'sap-avatar-logo',
        SecurityId: '20300',
        Config: [
            { Name: "SAP_ImportInactiveUsers", Encrypted: false },
            { Name: "SAP_ImportQuantificationData", Encrypted: false },
            { Name: "SAP_ImportConsumptionData", Encrypted: false },
            { Name: "SAP_LimitRoleAuthorizationData", Encrypted: false },
            { Name: "SAP_ImportDialogUsers", Encrypted: false },
            { Name: "SAP_ImportSystemUsers", Encrypted: false },
            { Name: "SAP_ImportCommunicationUsers", Encrypted: false },
            { Name: "SAP_ImportServiceUsers", Encrypted: false },
            { Name: "SAP_ImportReferenceUsers", Encrypted: false }
        ]
    },
    {
        Name: 'SAP Business One',
        ID: 'SAPB1',
        Type: 'SAPB1',
        LogoClass: 'sapb1-avatar-logo',
        SecurityId: '27070300',
        Config: []
    },
    {
        Name: 'Dynamics NAV',
        ID: 'NAV',
        Type: 'NAV',
        LogoClass: 'nav-avatar-logo',
        SecurityId: '23070300',
        Config: [
            { Name: "NAV_Database", Encrypted: false },
            { Name: "NAV_HostName", Encrypted: false },
            { Name: "NAV_Instance", Encrypted: false },
            { Name: "NAV_Port", Encrypted: false },
            { Name: "NAV_User", Encrypted: false },
            { Name: "NAV_Password", Encrypted: true },
            { Name: "NAV_Version", Encrypted: false },
            { Name: "NAV_ImportDisabledUsers", Encrypted: false },
            { Name: "NAV_ImportADUserGroups", Encrypted: false },
            { Name: "NAV_WSCompany", Encrypted: false },
            { Name: "NAV_WSDomain", Encrypted: false },
            { Name: "NAV_WSHostName", Encrypted: false },
            { Name: "NAV_WSInstance", Encrypted: false },
            { Name: "NAV_WSPort", Encrypted: false },
            { Name: "NAV_WSUser", Encrypted: false },
            { Name: "NAV_WSPassword", Encrypted: true }
        ],
        VersionOptions: [
            { Id: "0", Name: "NAV 2013" },
            { Id: "1", Name: "NAV 2013 R2" },
            { Id: "2", Name: "NAV 2015" },
            { Id: "3", Name: "NAV 2016" },
            { Id: "4", Name: "NAV 2017" },
            { Id: "5", Name: "NAV 2018" },
            { Id: "6", Name: "NAV 4/5/2009" }
        ]
    },
    {
        Name: 'Dynamics AX',
        ID: 'AX',
        Type: 'AX',
        LogoClass: 'ax-avatar-logo',
        SecurityId: '21300',
        Config: [
            { Name: "AX_AS_Import_AzureJobCollectionName", Encrypted: false },
            { Name: "AX_AS_Import_AzureJobId", Encrypted: false },
            { Name: "AX_ConnectorHostName", Encrypted: false },
            { Name: "AX_ConnectorPort", Encrypted: false },
            { Name: "AX_Language", Encrypted: false },
            { Name: "AX_SqlDatabase", Encrypted: false },
            { Name: "AX_SqlHostName", Encrypted: false },
            { Name: "AX_SqlInstance", Encrypted: false },
            { Name: "AX_SqlPort", Encrypted: false },
            { Name: "AX_SqlUser", Encrypted: false },
            { Name: "AX_Version", Encrypted: false },
            { Name: "AX_AS_ImportDisabledUsers", Encrypted: false },
            { Name: "AX_SqlPassword", Encrypted: true }
        ],
        VersionOptions: [
            { Id: "1", Name: "AX 2012" },
            { Id: "2", Name: "AX 2012 R2" },
            { Id: "3", Name: "AX 2012 R3" }
        ]
    },
    {
        Name: 'Acumatica',
        ID: 'AC',
        Type: 'AC',
        LogoClass: 'acumatica-avatar-logo',
        SecurityId: '19070300',
        Config: [
            { Name: "AC_ImportDisabledUsers", Encrypted: false },
            { Name: "AC_ImportType", Encrypted: false },
            { Name: "AC_Company", Encrypted: false },
            { Name: "AC_Locale", Encrypted: false },
            { Name: "AC_BaseUrl", Encrypted: false },
            { Name: "AC_UserName", Encrypted: false },
            { Name: "AC_Password", Encrypted: true },
            { Name: "AC_SqlDatabase", Encrypted: false },
            { Name: "AC_SqlHostname", Encrypted: false },
            { Name: "AC_SqlInstance", Encrypted: false },
            { Name: "AC_SqlPort", Encrypted: false },
            { Name: "AC_SqlUser", Encrypted: false },
            { Name: "AC_SqlPassword", Encrypted: true }
        ]
    },
    {
        Name: 'PeopleSoft',
        ID: 'PS',
        Type: 'PS',
        LogoClass: 'peoplesoft-avatar-logo',
        SecurityId: '28070300',
        Config: [
            { Name: "PS_ImportLockedAccounts", Encrypted: false },
            { Name: "PS_HostName", Encrypted: false },
            { Name: "PS_Password", Encrypted: true },
            { Name: "PS_Port", Encrypted: false },
            { Name: "PS_ServiceName", Encrypted: false },
            { Name: "PS_User", Encrypted: false },
            { Name: "PS_IM_BaseUrl", Encrypted: false },
            { Name: "PS_IM_Password", Encrypted: true },
            { Name: "PS_IM_Port", Encrypted: false },
            { Name: "PS_IM_UserName", Encrypted: false }
        ]
    },
    {
        Name: 'Oracle Cloud',
        ID: 'ORFC',
        Type: 'ORFC',
        LogoClass: 'orfc-avatar-logo',
        SecurityId: '30070300',
        Config: [
            { Name: "ORFC_UserName", Encrypted: false },
            { Name: "ORFC_BaseUrl", Encrypted: false },
            { Name: "ORFC_ImportSuspendedUsers", Encrypted: false },
            { Name: "ORFC_Password", Encrypted: true }
        ]
    },
    {
        Name: 'JD Edwards',
        ID: 'JDE',
        Type: 'JDE',
        LogoClass: 'jde-avatar-logo',
        SecurityId: '29070300',
        Config: [
            { Name: "JDE_ImportDisabledUsers", Encrypted: false },
            { Name: "JDE_User", Encrypted: false },
            { Name: "JDE_HostName", Encrypted: false },
            { Name: "JDE_ServiceName", Encrypted: true },
            { Name: "JDE_Port", Encrypted: false },
            { Name: "JDE_SystemDataSchema", Encrypted: false },
            { Name: "JDE_BusinessDataSchema", Encrypted: false },
            { Name: "JDE_ObjectLibrarianSchema", Encrypted: false },
            { Name: "JDE_Password", Encrypted: true },
            { Name: "JDE_VersionSchema", Encrypted: false }
        ]
    },
    {
        Name: 'Dynamics AX 2009',
        ID: 'AX5',
        Type: 'AX5',
        LogoClass: 'ax-avatar-logo',
        SecurityId: '31070300',
        Config: [
            { Name: "AX5_ImportDisabledUsers", Encrypted: false },
            { Name: "AX5_ConnectorPort", Encrypted: false },
            { Name: "AX5_ConnectorHostName", Encrypted: false }
        ]
    },
    {
        Name: 'Dynamics SL',
        ID: 'SL',
        Type: 'SL',
        LogoClass: 'sl-avatar-logo',
        SecurityId: '24070300',
        Config: []
    },
    {
        Name: 'Active Directory',
        ID: 'AD',
        Type: 'AD',
        LogoClass: 'active-directory-avatar-logo',
        SecurityId: '33300',
        Config: [
            { Name: "AD_HostName", Encrypted: false },
            { Name: "AD_Port", Encrypted: false },
            { Name: "AD_ImportDisabledUsers", Encrypted: false }
        ]
    },
    {
        Name: 'Entra ID',
        ID: 'AAD',
        Type: 'AAD',
        LogoClass: 'azure-active-directory-avatar-logo',
        SecurityId: '34300',
        Config: [
            { Name: "AAD_ClientId", Encrypted: false },
            { Name: "AAD_ClientSecret", Encrypted: true },
            { Name: "AAD_TenantId", Encrypted: false },
            { Name: "AAD_ImportDisabledUsers", Encrypted: false }
        ]
    },
    {
        Name: 'Dynamics 365 Business Central',
        ID: 'D365Bc',
        Type: 'D365Bc',
        LogoClass: 'dynamics-365-businesscentral-avatar-logo',
        SecurityId: '35070300',
        Config: [
            { Name: "D365Bc_Company", Encrypted: false },
            { Name: "D365Bc_ClientSecret", Encrypted: true },
            { Name: "D365Bc_Key", Encrypted: true },
            { Name: "D365Bc_Password", Encrypted: true },
            { Name: "D365Bc_InstanceName", Encrypted: false },
            { Name: "D365Bc_TenantId", Encrypted: false },
            { Name: "D365Bc_ClientId", Encrypted: false },
            { Name: "D365Bc_User", Encrypted: false },
            { Name: "D365Bc_BaseUrl", Encrypted: false },
            { Name: "D365Bc_ODataPort", Encrypted: false },
            { Name: "D365Bc_IsOnPremise", Encrypted: false },
            { Name: "D365Bc_ImportDisabledUsers", Encrypted: false },
            { Name: "D365Bc_UseLegacyPermissionEndpoints", Encrypted: false },
            { Name: "D365Bc_UseSecurityGroups", Encrypted: false }
        ],
        AuthTypeOptions: [
            { Id: "1", Name: "Web Service Access Key" },
            { Id: "2", Name: "OAuth" },
            { Id: "3", Name: "Windows" }
        ]
    },
    {
        Name: 'Coupa',
        ID: 'CPA',
        Type: 'CPA',
        LogoClass: 'coupa-avatar-logo',
        SecurityId: '36070300',
        Config: [
            { Name: "CPA_BaseUrl", Encrypted: false },
            { Name: "CPA_ApiKey", Encrypted: true },
            { Name: "CPA_ImportInactiveUsers", Encrypted: false },
            { Name: "CPA_ClientSecret", Encrypted: true },
            { Name: "CPA_ClientId", Encrypted: false },
            { Name: "CPA_AuthenticationType", Encrypted: false },
            { Name: "CPA_ExcludeSupplierUsers", Encrypted: false }
        ],
        AuthTypeOptions: [
            { Id: "1", Name: "API Key" },
            { Id: "2", Name: "OAuth2" }
        ]
    },
    {
        Name: 'SAP GRC',
        ID: 'SapGrc',
        Type: 'SapGrc',
        LogoClass: 'sap-avatar-logo',
        SecurityId: '37070300',
        Config: [
            { Name: "SapGrc_AppServerHost", Encrypted: false },
            { Name: "SapGrc_SystemNumber", Encrypted: false },
            { Name: "SapGrc_SystemId", Encrypted: false },
            { Name: "SapGrc_User", Encrypted: false },
            { Name: "SapGrc_Password", Encrypted: true },
            { Name: "SapGrc_Client", Encrypted: false },
            { Name: "SapGrc_Language", Encrypted: false },
            { Name: "SapGrc_PoolSize", Encrypted: false }
        ]
    },
    {
        Name: 'Workday',
        ID: 'WD',
        Type: 'WD',
        LogoClass: 'workday-avatar-logo',
        SecurityId: '38300',
        Config: [
            { Name: "WD_ImportInactiveUsers", Encrypted: false },
            { Name: "WD_BreakoutByCompany", Encrypted: false },
            { Name: "WD_Server", Encrypted: false },
            { Name: "WD_Tenant", Encrypted: false },
            { Name: "WD_User", Encrypted: false },
            { Name: "WD_Password", Encrypted: true },
            { Name: "WD_AuthType", Encrypted: false },
            { Name: "WD_RefreshToken", Encrypted: false },
            { Name: "WD_ClientId", Encrypted: false },
            { Name: "WD_ClientSecret", Encrypted: true }
        ]
    },
    {
        Name: 'Zuora',
        ID: 'Zuo',
        Type: 'Zuo',
        LogoClass: 'zuo-avatar-logo',
        SecurityId: '39070300',
        Config: [
            { Name: "Zuo_BaseUrl", Encrypted: false },
            { Name: "Zuo_ClientId", Encrypted: false },
            { Name: "Zuo_ClientSecret", Encrypted: true },
            { Name: "Zuo_ImportInactiveUsers", Encrypted: false }
        ]
    },
    {
        Name: 'Oracle Cloud EPM',
        ID: 'OREPM',
        Type: 'OREPM',
        LogoClass: 'orfc-avatar-logo',
        SecurityId: '40070300',
        Config: []
    },
    {
        Name: 'Azure',
        ID: 'AZ',
        Type: 'AZ',
        LogoClass: 'azure-avatar-logo',
        SecurityId: '43300',
        Config: [
            { Name: "AZ_ClientId", Encrypted: false },
            { Name: "AZ_ClientSecret", Encrypted: true },
            { Name: "AZ_TenantId", Encrypted: false },
            { Name: "AAD_ImportDisabledUsers", Encrypted: false }
        ]
    },
    {
        Name: 'SAP Ariba',
        ID: 'ARB',
        Type: 'ARB',
        LogoClass: 'ariba-avatar-logo',
        SecurityId: '45300',
        Config: [
            { Name: "ARB_ApiUrl", Encrypted: false },
            { Name: "ARB_TokenUrl", Encrypted: false },
            { Name: "ARB_ApiKey", Encrypted: true },
            { Name: "ARB_Realm", Encrypted: false },
            { Name: "ARB_Base64ClientSecret", Encrypted: true }
        ]
    },
    {
        Name: 'Azure DevOps',
        ID: 'ADO',
        Type: 'ADO',
        LogoClass: 'azure-devops-avatar-logo',
        SecurityId: '46300',
        Config: [
            { Name: "ADO_AzureResource", Encrypted: false },
            { Name: "ADO_PersonalAccessToken", Encrypted: true },
            { Name: "ADO_Organization", Encrypted: false }
        ]
    },
    {
        Name: 'Concur',
        ID: 'CNC',
        Type: 'CNC',
        LogoClass: 'concur-avatar-logo',
        SecurityId: '47300',
        Config: [
            { Name: "CNC_ClientId", Encrypted: false },
            { Name: "CNC_ClientSecret", Encrypted: true },
            { Name: "CNC_CompanyUsername", Encrypted: false },
            { Name: "CNC_CompanyPassword", Encrypted: true }
        ]
    }
];

export default allSupportedProducts;