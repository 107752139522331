export default /*@ngInject*/ function ($http, errorsDataService) {
    const service = {
        getConfigurationValueByName,
        getProductConfigurationValueByName,
        getConfigurationValues,
        getIdentityProviders,
        updateConfigurationValues,
        updateProductRiskEligibility,
        getStandardMitigations,
        getCompanyLogo,
        importCompanyLogo,
        deleteCompanyLogo
    };

    return service;

    //GET Requests
    async function getConfigurationValueByName(name) {
        try {
            return await $http.get(`${apiUrl}api/core/configuration/${name}`);
        } catch (err) {
            showError(err);
        }
    }

    async function getProductConfigurationValueByName(product, name) {
        try {
            return await $http.get(`${apiUrl}api/universal/products/${product}/configuration/${name}`);
        } catch (err) {
            showError(err);
        }
    }

    async function getConfigurationValues() {
        try {
            return await $http.get(`${apiUrl}api/core/configuration`);
        } catch (err) {
            showError(err);
        }
    }

    async function getIdentityProviders() {
        try {
            return await $http.get(`${apiUrl}api/auth/identityproviders`);
        } catch (err) {
            showError(err);
        }
    }

    async function getStandardMitigations() {
        try {
            return await $http.get(`${apiUrl}api/core/configuration/ARM_StandardMitigations`);
        } catch (err) {
            showError(err);
        }
    }

    async function getCompanyLogo() {
        return await $http.get(`${apiUrl}api/core/images`);
    }

    //PUT Requests
    async function updateConfigurationValues(data) {
        try {
            return await $http.put(`${apiUrl}api/core/configuration`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function updateProductRiskEligibility(productType, riskEligible) {
        try {
            return await $http.put(`${apiUrl}api/universal/${productType}/accesslevels/indirect/riskeligibility/${riskEligible}`);
        } catch (err) {
            showError(err);
            sendError(err);
        }

    }
    //POST Requests

    async function importCompanyLogo(fd) {
        try {
            return await $http.post(`${apiUrl}api/core/images`, fd, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined }
            });
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    //DELETE Requests
    async function deleteCompanyLogo() {
        return await $http.delete(`${apiUrl}api/core/images`);
    }


    function sendError(err) {
        throw err;
    }

    function showError(err) {
        errorsDataService.catch(err);
    }
}